import { createRouterMiddleware, createRouterReducerMapObject, push, ReduxRouter } from '@lagunovsky/redux-react-router'
import { configureStore, combineReducers, AnyAction } from '@reduxjs/toolkit'
import authenticationReducer from './authentication/authenticationSlice'
import * as AuthenticationSlice from './authentication/authenticationSlice'
import blocklyProjectsReducer from './blocklyProjects/blocklyProjectsSlice'
import machineLearningProjectsReducer from './machineLearningProjects/machineLearningProjectsSlice'
import codespaceProjectsReducer from './codespaceProjects/codespaceProjectsSlice'
import * as MachineLearningProjectsSlice from './machineLearningProjects/machineLearningProjectsSlice'
import * as BlocklyProjectsSlice from './blocklyProjects/blocklyProjectsSlice'
import kebbiMaterialsReducer from './kebbiMaterials/kebbiMaterialsSlice'
import navigationReducer from './navigation/navigationSlice'
import robotConciergeReducer from './robotConcierge/robotConciergeSlice'
import { history } from 'src/routes/history'
import routerCustomReducer from './router/routerCustomSlice'
import * as SchoolManagementSlice from './schoolManagement/schoolManagementSlice'
import schoolManagementReducer from './schoolManagement/schoolManagementSlice'
import * as CodespaceProjectsSlice from './codespaceProjects/codespaceProjectsSlice'

export const RESET_APP = "RESET_APP"

const historyRouterMiddleware = createRouterMiddleware(history)

const appReducer =
    combineReducers({
        authentication: authenticationReducer,
        blocklyProjects: blocklyProjectsReducer,
        machineLearningProjects: machineLearningProjectsReducer,
        codespaceProjects: codespaceProjectsReducer,
        kebbiMaterials: kebbiMaterialsReducer,
        robotConcierge: robotConciergeReducer,
        ...createRouterReducerMapObject(history),
        routerCustom: routerCustomReducer,
        schoolManagement: schoolManagementReducer,
        navigation: navigationReducer,
    });

const reducerProxy = (
    state: ReturnType<typeof appReducer> | undefined,
    action: AnyAction
) => {
    /* if you are using RTK, you can import your action and use it's type property instead of the literal definition of the action  */
    if (action.type === RESET_APP) {
        return appReducer(undefined, action);
    }

    return appReducer(state, action);
};

// configureStore already sets up redux-thunk middleware to allow us to have async logic with thunks for reducer
// see https://redux.js.org/tutorials/essentials/part-2-app-structure#writing-async-logic-with-thunks
export const store = configureStore({
    reducer: reducerProxy,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                // // Ignore these action types
                // ignoredActions: ['your/action/type'],
                // Ignore these field paths in all actions
                // ignoredActionPaths: ['payload.file'],
                // Ignore these paths in the state
                // ignoredPaths: ['blocklyProjects.currentWorkspace.files.(.*).file'],
            },
        })
            .concat(historyRouterMiddleware)
            .concat(BlocklyProjectsSlice.listenerMiddleware.middleware)
            .concat(AuthenticationSlice.listenerMiddleware.middleware)
            .concat(MachineLearningProjectsSlice.listenerMiddleware.middleware)
            .concat(CodespaceProjectsSlice.listenerMiddleware.middleware)
            .concat(SchoolManagementSlice.listenerMiddleware.middleware)
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch