import { createAsyncThunk } from "@reduxjs/toolkit"
import { AppDispatch, RootState } from "../../../store"
import { Quiz, QuizCatalog, UpdateQuizesMutation, UpdateQuizesMutationVariables } from "src/globalUtils/API"
import { GraphQLQuery, GraphQLResult } from "aws-amplify/api"
import * as mutations from 'src/globalUtils/graphql/mutations';
import { graphQLClient } from "../../../util";

export const updateQuizes = createAsyncThunk<
    {
        organizationID: string,
        updatedQuizes: Quiz[],
        updatedQuizCatalog: QuizCatalog
    }, // output
    {
        updateQuizesVariables: UpdateQuizesMutationVariables
    }, // input argument
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('robotConcierge/updateQuizes', async ({ updateQuizesVariables }, { dispatch, getState }) => {
    const { currentSelectedOrganizationID } = getState().authentication
    const response: GraphQLResult<UpdateQuizesMutation> = await graphQLClient.graphql<GraphQLQuery<UpdateQuizesMutation>>({
        query: mutations.updateQuizes,
        variables: updateQuizesVariables
    });
    if (response.errors || response.data.updateQuizes.errorMsg) {
        throw new Error(response.data.updateQuizes.errorMsg ?? "Failed to update quiz catalog")
    }
    return {
        organizationID: currentSelectedOrganizationID!!,
        updatedQuizes: response.data.updateQuizes.createdOrUpdatedQuizes!!,
        updatedQuizCatalog: response.data.updateQuizes.updatedQuizCatalog!!
    }
})